<template lang="pug">
    section#galeria
        img(src="@images/objetos/camera.png").camera
        img(src="@images/objetos/fotos.png").fotos
        .content
            h2 Galeria
            .text
                p Siga explorando as Experiências Land Rover.
            .galeria
                .trail(ref="trail")
                    ul(:style="galeriaStyle", ref="listItems").items
                        li(v-for="i in 400").item
                            img(:src="require(`@images/galeria/landrover_experience_${ i < 10 ? '00' + i : i < 100 ? '0' + i : i }.jpg`)")
                .controls
                    button(:disabled="page <= 1", @click="toggleGaleria(page - 1)") 
                        include ../../../assets/svgs/chevron-left-solid.svg
                    button(:disabled="page >= totalPages", @click="toggleGaleria(page + 1)") 
                        include ../../../assets/svgs/chevron-right-solid.svg
</template>

<script>
export default {
    name: "section-galeria",
    data() {
        return {
            totalPages: window.innerWidth > 600 ? Math.ceil(400/6) : Math.ceil(400/1),
            page: 1,
        }
    },
    computed: {
        galeriaStyle() {
            let width = this.$refs.trail ? this.$refs.trail.offsetWidth : 0
            let styles = this.$refs.listItems ? window.getComputedStyle(this.$refs.listItems) : null
            let gap = styles != null ? parseInt(styles.getPropertyValue('column-gap')) : 0
            return {
                transform: `translateX(-${(this.page - 1) * (width + gap)}px)`
            }
        },
    },
    mounted() {
        setTimeout(() => {
            const scenes = []
            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#galeria',
                triggerHook: 0.7,
                duration: 500
            }).setTween('#background .bg-experience', { 
                css: { 
                    transform: `translateX(-100%)`,
                    opacity: 0
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#galeria',
                triggerHook: 0.5,
                duration: 500
            }).setTween('#background .bg-galeria', { 
                css: { 
                    transform: `translateX(0)`,
                    opacity: window.innerWidth > 1024 ? 1 : .5
                }
            }))
            
            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#galeria',
                triggerHook: 0.5,
                duration: 350
            }).setTween('#galeria .camera', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#galeria',
                triggerHook: 0.5,
                duration: 350
            }).setTween('#galeria .fotos', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.forEach(scene => this.$scrollmagic.addScene(scene))
        }, 200)
    },
    methods: {
        toggleGaleria(page) {
            this.page = page
        },
    },
}
</script>

<style lang="stylus" scoped src="./Galeria.styl"></style>